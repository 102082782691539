.feature-icon {
    width: 4rem;
    height: 4rem;
    border-radius: .75rem;
  }
  
  .icon-square {
    width: 3rem;
    height: 3rem;
    border-radius: .75rem;
  }
  
  .text-shadow-1 { text-shadow: 0 .125rem .25rem rgba(0, 0, 0, .25); }
  .text-shadow-2 { text-shadow: 0 .25rem .5rem rgba(0, 0, 0, .25); }
  .text-shadow-3 { text-shadow: 0 .5rem 1.5rem rgba(0, 0, 0, .25); }
  
  .card-cover {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  
  .feature-icon-small {
    width: 3rem;
    height: 3rem;
  }
  